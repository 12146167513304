/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    code: "code",
    h2: "h2",
    ol: "ol",
    li: "li",
    ul: "ul",
    hr: "hr"
  }, _provideComponents(), props.components), {Specialization, Skill, Divider, Grid, GridItem, Traits, Card, Trait, Boon, Label, SpecialActionKey, Condition, Control, Information, Instability} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Label) _missingMdxReference("Label", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Heal Renegade",
    name: "Renegade"
  })), " plays with a mix of harrier's and cleric's gear for maximum power and healing power while still hitting 100% boon duration."), "\n", React.createElement(_components.p, null, "Since the damage and life steals of ", React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " are based on the ", React.createElement(_components.strong, null, React.createElement(Specialization, {
    text: "Heal Renegade",
    name: "Renegade"
  })), "'s own power and healing power, the ", React.createElement(Specialization, {
    text: "Heal Renegade",
    name: "Renegade"
  }), " aims to maximize those stats."), "\n", React.createElement(_components.p, null, "Feel free to run full Harrier if you are confident that you don't need the extra healing power or toughness from cleric. The more agony resistance you have, the more cleric you can afford to run and still hit 100% boon duration."), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1: "Salvation",
    traits1Selected: "Invoking Harmony,Words of Censure,Generous Abundance",
    traits2: "Devastation",
    traits2Selected: "Unsuspecting Strikes,Assassins Presence,Swift Termination",
    traits3: "Renegade",
    traits3Selected: "Wrought-Iron Will,All for One,Lasting Legacy",
    traits1Id: "12",
    traits1SelectedIds: "1823,1819,1815",
    traits2Id: "15",
    traits2SelectedIds: "1767,1786,1800",
    traits3Id: "63",
    traits3SelectedIds: "2120,2108,2100"
  }), React.createElement(Card, {
    title: "Second trait variant"
  }, React.createElement(_components.p, null, "If you run out of energy too quickly, we recommend the Salvation/Invocation variant. This variant is also better for fractals where ", React.createElement(Trait, {
    name: "Assassins Presence",
    id: "1786"
  }), " doesn't help your party. This would include fractals where damage is time-gated and where you can't critically hit the enemies that matter."), React.createElement(_components.p, null, "You should consider running this trait variant in fractals like Molten Furnace and Captain Mai Trin."), React.createElement(_components.p, null, "Swap Devastation with this line:"), React.createElement(Traits, {
    unembossed: true,
    traits1Id: "3",
    traits1: "Invocation",
    traits1SelectedIds: "1761,1774,1791",
    traits1Id: "3",
    traits1SelectedIds: "1761,1774,1791"
  }), React.createElement(_components.p, null, "Template code:"), React.createElement(_components.p, null, React.createElement(_components.code, null, "[&DQkDJgw/Pz/cEdwRBhLUESsSBhLUESsSyhHKERIRDw4GEtQRKxIAAAAAAAA=]")))), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Legend Combos"
  }, React.createElement(_components.h2, null, "Normal"), React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    disableText: true,
    size: "big",
    id: "41858"
  }), React.createElement(Skill, {
    name: "Legendary Centaur Stance",
    disableText: true,
    size: "big",
    id: "28195"
  }), React.createElement(_components.h2, null, "When ", React.createElement(Boon, {
    name: "Stability"
  }), " is needed"), React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    disableText: true,
    size: "big",
    id: "41858"
  }), React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    disableText: true,
    size: "big",
    id: "28419"
  }), React.createElement(_components.h2, null, "No Pain No Gain ", React.createElement(Label, null, "Use the Salvation / Invocation / Renegade variant for more energy!")), React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    disableText: true,
    size: "big",
    id: "41858"
  }), React.createElement(Skill, {
    name: "Legendary Demon Stance",
    disableText: true,
    size: "big",
    id: "28494"
  }), React.createElement(_components.h2, null, "For more explanation go to the Guides tab above.")), React.createElement(Card, {
    title: "Healing Basics"
  }, React.createElement(_components.p, null, "Your healing skills in order of effectiveness are:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "45773",
    id: "45773"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "45686",
    id: "45686"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "27025",
    id: "27025"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Trait, {
    id: "1815",
    id: "1815"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "27356",
    id: "27356"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "29002",
    id: "29002"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    id: "28427",
    id: "28427"
  }), "\n"), "\n"), React.createElement(_components.p, null, "With ", React.createElement(Skill, {
    id: "45773",
    id: "45773"
  }), " you can sustain your group for the whole time you have energy on ", React.createElement(Skill, {
    id: "41858",
    id: "41858"
  }), ". When you are about to swap off, you should use your ", React.createElement(Skill, {
    id: "45686",
    id: "45686"
  }), " on the group as it lingers even when you swap to a different legend and will maintain the heals. ", React.createElement(Skill, {
    id: "28195",
    id: "28195"
  }), " has a good proactive way to lessen the incoming damage by proper usage of ", React.createElement(Skill, {
    id: "26821",
    id: "26821"
  }), ". You also have heals when you use your basic staff auto attack ", React.createElement(Skill, {
    id: "29002",
    id: "29002"
  }), " (note that this is the last part of the auto attack sequence).")))), "\n", React.createElement(Divider, {
    text: "Guide"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "12"
  }, React.createElement(Card, {
    title: "Abilities"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Citadel Order Skills")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " - Share two stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " for each ", React.createElement(SpecialActionKey, {
    name: "kallas fervor"
  }), " stack."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Citadel Bombardment",
    id: "41294"
  }), " - Fire ten missiles that inflict high damage, and ", React.createElement(Condition, {
    name: "Burning"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " - Share four pulses of ", React.createElement(Boon, {
    name: "Alacrity"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Sword Skills")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Preparation Thrust",
    id: "29057"
  }), " - The auto-attack chain cleaves and provides ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on the two first attacks. The third attack creates an exploding rift on up to three targets, with each explosion hitting three targets. Potentially hitting nine times."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " - High damage skill with low cooldown and energy cost, also provides ", React.createElement(Condition, {
    name: "Chilled"
  }), ". The second attack deals increased damage to isolated enemies."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Unrelenting Assault",
    id: "26699"
  }), " - A two second evade that also provides ", React.createElement(Boon, {
    name: "Might"
  }), " to oneself."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " - High damage skill, however should be used in key moments due to the energy cost, cooldown, and application of ", React.createElement(Condition, {
    name: "Vulnerability"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Death strike",
    id: "27074"
  }), " - Shadowsteps to target, grants two hits with decent damage. Should be used in key moments, unless energy and timing allow it to be used for damage."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Staff Skills")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rapid Swipe",
    id: "29180"
  }), " - The autoattack chain cleaves and the last hit provides a decent amount of healing to allies."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Menders Rebuke",
    id: "29145"
  }), " - Provides ", React.createElement(Condition, {
    name: "Weakness"
  }), " and a small amount of healing to allies."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Warding Rift",
    id: "29288"
  }), " - A two second block that ", React.createElement(Condition, {
    name: "Blinded"
  }), " foes."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Renewing Wave",
    id: "29321"
  }), " - Provides a blast for a combo field, cleanses two conditions and heals allies for a fairly large amount."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " - The strongest Crowd Control skill in your arsenal, which grants between three and nine hits of ", React.createElement(Control, {
    name: "Knockback"
  }), "."), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Notable Traits")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Charged Mists",
    id: "1791"
  }), " - Grant 25 more energy when you swap Legends below 10 energy."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Tranquil Balance"
  }), " - When you are above the health threshold your healing to allies is increased."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Invoking Harmony",
    id: "1823"
  }), " - When you invoke another legend, your healing is increased for 10 seconds."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Serene Rejuvenation",
    id: "1814"
  }), " - When you heal with ", React.createElement(Skill, {
    name: "Natural Harmony",
    id: "27025"
  }), " you also apply ", React.createElement(Boon, {
    name: "Alacrity"
  }), " to allies near the tablet."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Lasting Legacy",
    id: "2100"
  }), " - ", React.createElement(Skill, {
    name: "Heroic Command",
    id: "44076"
  }), " shares more stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " and the duration is increased with 50%."), "\n", React.createElement(_components.li, null, React.createElement(Trait, {
    name: "Righteous Rebel",
    id: "2182"
  }), " - The duration of ", React.createElement(Boon, {
    name: "Alacrity"
  }), " shared from ", React.createElement(Skill, {
    name: "Orders from Above",
    id: "45537"
  }), " is increased with 50%."), "\n")))), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "12"
  }, React.createElement(Card, {
    title: "Legends"
  }, React.createElement(Information, null, React.createElement(_components.p, null, "You will need to reconsider the legends you are running in almost every fractal and therefore it is a nice habit to develop to check them after each fractal you have done. Some fractals demand three to four different legends in only one fractal. Below you will find the three most common setups that will serve you fine as well as a description of the skills in each legend.")), React.createElement(_components.h2, null, "Legends Setups:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  })), " and ", React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Centaur Stance",
    id: "28195"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This is your most used setup that has the most healing output as well as a projectile block and extra condition cleanse."), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  })), " and ", React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Demon Stance",
    id: "28494"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This is a specific setup for the instability ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), " and for when you want to increase your damage output on bosses that generate ", React.createElement(Boon, {
    name: "Protection"
  }), " on themselves or when you need to pull adds using ", React.createElement(Skill, {
    name: "Call to Anguish",
    id: "27917"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  })), " and ", React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  })), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "This is the setup you want to use on a few CM bosses (Skorvald and Artsariiv) as well as some fractals (Chaos last boss, possibly in Siren's Reef)."), "\n"), "\n"), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Renegade Stance",
    id: "41858"
  }))), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Breakrazors Bastion",
    id: "45686"
  }), " - Summons a spirit that heals you on cast and pulses heal to allies in an area around it. Reduces damages from conditions by 50%."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Razorclaws Rage",
    id: "42949"
  }), " - Summons a spirit that causes your party to inflict ", React.createElement(Condition, {
    name: "Bleeding"
  }), " on attack."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " - Summons a spirit that ", React.createElement(Control, {
    name: "Daze"
  }), "s enemies in an area around it for ¼ seconds once per second for six seconds."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Icerazors Ire",
    id: "40485"
  }), " - Summons a spirit that deals high damage to enemies around it and inflicts ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " on the targets."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Soulcleaves Summit",
    id: "45773"
  }), " - Summons a spirit that enchants your party's attacks with life steal. Increases damage dealt by your party while healing them for each attack."), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Centaur Stance",
    id: "28195"
  }))), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Project Tranquility",
    id: "29148"
  }), " - Summon Ventari's Tablet which heals nearby allies every few seconds."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ventaris Will",
    id: "28427"
  }), " - Move Ventari's Tablet to the target location."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Protective Solace",
    id: "26821"
  }), " - ", React.createElement(_components.strong, null, "Toggle:"), " Summons a protective barrier that ", React.createElement(_components.strong, null, "absorbs"), " (not reflect) projectiles around the tablet."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Natural Harmony",
    id: "27025"
  }), " - Heals the party for a decent amount around the tablet."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Purifying Essence",
    id: "27715"
  }), " - Cleanses three conditions from allies near the tablet and heals per condition cleansed."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Energy Expulsion",
    id: "27356"
  }), " - Drains your remaining energy to explode Ventari's Tablet. Cleanses one condition per 10 energy drained. Ventari's Tablet shatters into smaller fragments that can be picked up by allies for a small heal."), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Demon Stance",
    id: "28494"
  }))), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Empowering Misery",
    id: "28219"
  }), " - A decent self-heal that heals more for each unique condition on you."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Pain Absorption",
    id: "27322"
  }), " - Draw one condition from nearby allies and provide ", React.createElement(Boon, {
    name: "Resistance"
  }), " to your party. Also works as a stunbreak."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Banish Enchantment",
    id: "27505"
  }), " - Removes three boons from enemies in a line up to five unique enemies and causes ", React.createElement(Condition, {
    name: "Chilled"
  }), " on enemies hit."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call to Anguish",
    id: "27917"
  }), " - Jump to a location and pull five nearby enemies to your location inflicting ", React.createElement(Condition, {
    name: "Chilled"
  }), " on them."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Embrace the Darkness",
    id: "28287"
  }), " - ", React.createElement(_components.strong, null, "Toggle:"), " Deal damage to nearby enemies and pulse ", React.createElement(Condition, {
    name: "Torment"
  }), " to them. Drains energy when toggled on."), "\n"), React.createElement(_components.hr), React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }))), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Soothing Stone",
    id: "27372"
  }), " - Powerful self heal and condition removal."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Inspiring Reinforcement",
    id: "28516"
  }), " - Grant ", React.createElement(Boon, {
    name: "Stability"
  }), " to your party and inflict ", React.createElement(Condition, {
    name: "Weakness"
  }), " and damage to enemies standing in the field."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Forced Engagement",
    id: "26679"
  }), " - Shoot out a chain to your target that applies ", React.createElement(Condition, {
    name: "Taunt"
  }), " and ", React.createElement(Condition, {
    name: "Slow"
  }), " to your target and four nearby enemies."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Vengeful Hammers",
    id: "26557"
  }), " - ", React.createElement(_components.strong, null, "Toggle:"), " Summon hammers to spin around you dealing damage to nearby enemies while draining your energy."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Rite of the Great Dwarf",
    id: "27975"
  }), " - Stunbreak, which reduces incoming damage by 50% for your party."), "\n")))), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "12"
  }, React.createElement(Card, {
    title: "Defiance Bar"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Hard Crowd Control")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Surge of the Mists",
    id: "28978"
  }), " - Up to nine hits of 150 ", React.createElement(Control, {
    name: "Knockback"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Darkrazors Daring",
    id: "41220"
  }), " - Up to six hits of 100 ", React.createElement(Control, {
    name: "Daze"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Jade Winds",
    id: "28406"
  }), " - 300 ", React.createElement(Control, {
    name: "Stun"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Call to Anguish",
    id: "27917"
  }), " - 150 ", React.createElement(Control, {
    name: "Pull"
  }), "."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Soft Crowd Control")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Chilling Isolation",
    id: "29233"
  }), " - ", React.createElement(Condition, {
    name: "Chilled"
  }), " for 33 per second."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shackling Wave",
    id: "28472"
  }), " - ", React.createElement(Condition, {
    name: "Immobile"
  }), " for 50 per second."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Forced Engagement",
    id: "26679"
  }), " - ", React.createElement(Condition, {
    name: "Taunt"
  }), " and ", React.createElement(Condition, {
    name: "Slow"
  }), " for 75 and 50 per second."), "\n")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
